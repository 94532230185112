import LoopSlider from "./LoopSlider";

let homeBannerSlider = new Swiper(".home-banner-slider .swiper", {
	slidesPerView: 1,
	spaceBetween: 12,
	autoplay: {
		delay: 4000,
	},
	pagination: {
		el: ".home-banner-slider .swiper-pagination",
		clickable: true,
	},
	navigation: {
		nextEl: ".home-banner-slider .swiper-btn-next",
		prevEl: ".home-banner-slider .swiper-btn-prev",
	},
	loop: true,
});

let newsGallerySlider = new Swiper(".news-detail-gallery .swiper", {
	slidesPerView: 1,
	spaceBetween: 12,
	pagination: {
		el: ".news-detail-gallery .swiper-pagination",
		clickable: true,
	},
	navigation: {
		nextEl: ".news-detail-gallery .swiper-btn-next",
		prevEl: ".news-detail-gallery .swiper-btn-prev",
	},
	loop: true,
});

let promoBannerSlider = new Swiper(".promo-banner-slider .swiper", {
	slidesPerView: 1,
	spaceBetween: 12,
	autoplay: {
		delay: 4000,
	},
	navigation: {
		nextEl: ".promo-banner-slider .swiper-btn-next",
		prevEl: ".promo-banner-slider .swiper-btn-prev",
	},
	loop: true,
	breakpoints: {
		576: {
			slidesPerView: 2,
		},
		768: {
			slidesPerView: 2,
			spaceBetween: 16,
		},
		1024: {
			slidesPerView: 2,
			spaceBetween: 32,
		},
	},
});

const partnerSlider = new Swiper(".partner-slider .swiper", {
	slidesPerView: 2,
	spaceBetween: 16,
	loop: true,
	rewind: true,
	autoplay: {
		delay: 4000,
	},
	navigation: {
		nextEl: ".partner-slider .swiper-btn-next",
		prevEl: ".partner-slider .swiper-btn-prev",
	},
	breakpoints: {
		576: {
			slidesPerView: 3,
		},
		768: {
			slidesPerView: 4,
		},
		1024: {
			slidesPerView: 6,
			spaceBetween: 32,
		},
	},
});

const homeManufacturerSlider = new Swiper(".home-manufacturer-slider .swiper", {
	slidesPerView: 3,
	spaceBetween: 16,
	loop: true,
	speed: 2000,
	autoplay: {
		delay: 0,
	},
	breakpoints: {
		576: {
			slidesPerView: 4,
		},
		768: {
			slidesPerView: 5,
		},
		1024: {
			slidesPerView: 8,
			spaceBetween: 24,
		},
		1280: {
			slidesPerView: 12,
			spaceBetween: 24,
		},
	},
});

const homeCategorySlider = new Swiper(".home-category-slider .swiper", {
	slidesPerView: 3,
	initialSlide: activeIndexSubnav,
	navigation: {
		nextEl: ".home-category-slider .swiper-btn-next",
		prevEl: ".home-category-slider .swiper-btn-prev",
	},
	breakpoints: {
		576: {
			slidesPerView: 4,
		},
		768: {
			slidesPerView: 4,
		},
		1024: {
			slidesPerView: 4,
		},
		1280: {
			slidesPerView: 4,
		},
	},
	on: {
		lock: function () {
			$(".home-category-slider .swiper-wrapper").addClass(
				"justify-center"
			);
		},
	},
});

const activeIndexSubnav = $(".product-subnav-item.active").parent().index();

const productSubnavSlider = new Swiper(".product-subnav .swiper", {
	slidesPerView: 2,
	spaceBetween: 12,
	initialSlide: activeIndexSubnav,
	navigation: {
		nextEl: ".product-subnav .swiper-btn-next",
		prevEl: ".product-subnav .swiper-btn-prev",
	},
	breakpoints: {
		576: {
			slidesPerView: 6,
		},
		768: {
			slidesPerView: 8,
		},
		1024: {
			slidesPerView: 10,
			spaceBetween: 24,
		},
	},
});

const activeIndexSlideNav = $(".product-slide-nav-item.active")
	.parent()
	.index();

const productSlideNavSlider = new Swiper(".product-slide-nav .swiper", {
	slidesPerView: 2,
	spaceBetween: 16,
	initialSlide: activeIndexSlideNav,
	grid: {
		rows: 2,
		fill: "row",
	},
	navigation: {
		nextEl: ".product-slide-nav .swiper-btn-next",
		prevEl: ".product-slide-nav .swiper-btn-prev",
	},
	breakpoints: {
		576: {
			slidesPerView: 4,
		},
		768: {
			slidesPerView: 5,
		},
		1024: {
			slidesPerView: 7,
			spaceBetween: 24,
		},
		1280: {
			slidesPerView: 7,
			spaceBetween: 24,
		},
	},
});

if ($(window).width() < 1025) {
	const suggestProductSlider = new Swiper(".suggest-product .swiper", {
		slidesPerView: 1,
		spaceBetween: 16,
		initialSlide: activeIndexSlideNav,
		grid: {
			rows: 3,
			fill: "row",
		},
		autoplay: {
			delay: 4000,
		},
		breakpoints: {
			1024: {
				slidesPerView: 3,
				spaceBetween: 32,
			},
		},
	});
}

const newsSlider = new LoopSlider(".news-slider", {
	576: {
		spaceBetween: 16,
		slidesPerView: 2,
	},
	768: {
		spaceBetween: 16,
		slidesPerView: 2,
	},
	1024: {
		spaceBetween: 32,
		slidesPerView: 3,
	},
});

const news2Slider = new LoopSlider(".news-slider-2-items", {
	576: {
		spaceBetween: 16,
		slidesPerView: 2,
	},
	768: {
		spaceBetween: 16,
		slidesPerView: 2,
	},
	1024: {
		spaceBetween: 32,
		slidesPerView: 2,
	},
});

const productSlider = new LoopSlider(
	".product-slider",
	{
		576: {
			spaceBetween: 16,
			slidesPerView: 3,
		},
		768: {
			spaceBetween: 16,
			slidesPerView: 4,
		},
		1024: {
			spaceBetween: 12,
			slidesPerView: 5,
		},
		1280: {
			spaceBetween: 20,
			slidesPerView: 5,
		},
		1440: {
			spaceBetween: 40,
			slidesPerView: 5,
		},
	},
	{
		slidesPerView: 2,
	}
);

const produc6tSlider = new LoopSlider(
	".product-6-slider",
	{
		576: {
			spaceBetween: 16,
			slidesPerView: 3,
		},
		768: {
			spaceBetween: 16,
			slidesPerView: 4,
		},
		1024: {
			spaceBetween: 16,
			slidesPerView: 4,
		},
		1280: {
			spaceBetween: 20,
			slidesPerView: 6,
		},
	},
	{
		slidesPerView: 2,
	}
);

const filterSlider = new LoopSlider(".filter-swiper", {
	576: {
		spaceBetween: 16,
		slidesPerView: 2,
	},
	768: {
		spaceBetween: 16,
		slidesPerView: 2,
	},
	1024: {
		spaceBetween: 16,
		slidesPerView: 3,
	},
});

window.filterSlider = filterSlider;
